



















































































































import Vue, { VueConstructor } from 'vue'
import api from '@/api'
import { TransactionChecker } from '@/api/transaction-checker'

import BalanceCard from '@/components/BalanceCard.vue'
import Organization from '../../../store/models/Organization'
import OrganizationBalance from '../../../store/models/OrganizationBalance'
import Transaction from '../../../store/models/Transaction'
import { ApiResponse } from '../../../api'
import uiState from '../../../ui-state'
import AccountMixin from '../../../mixins/accountMixin'
import Account from '../../../store/models/Account'
import { mapGetters } from 'vuex'

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AccountMixin>
>).extend({
  components: {
    BalanceCard
  },

  mixins: [AccountMixin],

  data() {
    return {
      form: {
        phone: '',
        amount: 1,
        otm: 'mtn'
      },
      transactionChecker: TransactionChecker.create(),
      uiState: uiState([
        'idle',
        'creating',
        'checking_status',
        'successful',
        'error_failed'
      ])
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    organization(): Organization {
      return Organization.loggedIn() as Organization
    },

    creating(): boolean {
      return this.uiState.in(['creating'])
    },

    checkingStatus(): boolean {
      return this.uiState.is('checking_status')
    },

    showSuccessMessage(): boolean {
      return this.uiState.is('successful')
    },

    showFailureMessage(): boolean {
      return this.uiState.is('error_failed')
    },

    moovBalance(this: any): number {
      return this.soldeRembourssementMoov
    },
    mtnBalance(this: any): number {
      return this.soldeRembourssementMtn
    },
    totalBalance(this: any): number {
      return this.moovBalance + this.mtnBalance
    }
  },

  mounted() {
    this.transactionChecker
      .onSuccess((transaction: Transaction) => {
        this.uiState.set('successful')
        OrganizationBalance.incrementBalance({
          organizationId: this.organization.id,
          type: 'refund',
          otm: transaction.otm,
          amount: transaction.amount
        })
        Account.api().fetchAll()
      })
      .onFailure(() => {
        this.uiState.set('error_failed')
      })
    // .onError((error: any) => {
    //   if (error.message === 'too_long') {
    //     this.showTooLong = true
    //   }
    // })
  },

  methods: {
    onOtmChecked(otm: string, checked: boolean): void {
      if (checked) {
        this.$set(this.form, 'otm', otm)
      }
    },

    async onSubmit() {
      try {
        this.uiState.set('creating')
        const response: ApiResponse = await api.creditOrganizationAccount({
          organizationId: this.organization.id,
          //accountType: 'refund',
          productId: this.$route.params.productId,
          otm: this.form.otm,
          phone: this.form.phone.replace(/ /g, ''),
          amount: this.form.amount
        })

        if (response.isSuccessful) {
          this.uiState.set('checking_status', response.data)
          this.transactionChecker.check(response.data.id)
        } else {
          console.log(response)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
})
